import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../layouts/default"

import Breadcrumb from "../components/breadcrumb"
import Bullet from "../components/bullet"
import BulletList from "../components/bullet-list"
import Column from "../components/column"
import Columns from "../components/columns"
import Constrain from "../components/constrain"
import Paragraph from "../components/paragraph"
import Grid from "../components/grid"
import Heading from "../components/heading"
import Inline from "../components/inline"
import Button from "../components/button"
import Image from "../components/image"
import PageTitle from "../components/page-title"
import Seo from "../components/seo"
import Stack from "../components/stack"
import SectionSpacing from "../components/section-spacing"
import Trivia from "../components/trivia"
import TextLink from "../components/textlink"

const English = () => {
  const data = useStaticQuery(graphql`
    query SprachenEnglishQuery {
      turmzimmer: file(relativePath: { eq: "sprachen/turmzimmer.jpg" }) {
        ...largeImage
      }
      interaktion: file(relativePath: { eq: "sprachen/interaktion.jpg" }) {
        ...largeImage
      }
      medienstation: file(relativePath: { eq: "sprachen/medienstation.jpg" }) {
        ...largeImage
      }
      laufstrecke: file(relativePath: { eq: "sprachen/laufstrecke.jpg" }) {
        ...largeImage
      }
      garten: file(relativePath: { eq: "sprachen/garten.jpg" }) {
        ...largeImage
      }
      nummern: file(relativePath: { eq: "sprachen/nummern.jpg" }) {
        ...mediumImage
      }
      english: file(relativePath: { eq: "sprachen/english.jpg" }) {
        ...mediumImage
      }
      stadtfriedhof: file(relativePath: { eq: "sprachen/stadtfriedhof.jpg" }) {
        ...mediumImage
      }
      stift: file(relativePath: { eq: "sprachen/evangelisches-stift.jpg" }) {
        ...mediumImage
      }
      burse: file(relativePath: { eq: "sprachen/burse.jpg" }) {
        ...mediumImage
      }
      aussenansicht: file(
        relativePath: { eq: "sprachen/turm-aussenansicht.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 500, height: 400, layout: CONSTRAINED)
        }
      }
      umfrage: file(relativePath: { eq: "neuigkeiten/umfrage.jpg" }) {
        ...largeImage
      }
    }
  `)

  return (
    <Layout backdrop="aussen">
      <Seo
        title="English"
        description="The Hölderlin Tower is named after the poet Friedrich Hölderlin (1770-1843). In that tower he lived during the second half of his life, from 1806 to 1843. It is the place where his last poems were composed, often signed under the pseudonym »Scardanelli«. While living there, he used to be visited by contemporaries, admirers and other authors."
      />
      <SectionSpacing>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "English",
                link: "/english",
              },
            ]}
          />
          <PageTitle>Hölderlin Tower</PageTitle>
          <Constrain>
            <Paragraph dropcap={true} lang="en">
              The Hölderlin Tower is named after the poet Friedrich Hölderlin
              (1770-1843). In that tower he lived during the second half of his
              life, from 1806 to 1843. It is the place where his last poems were
              composed, often signed under the pseudonym »Scardanelli«. While
              living there, he used to be visited by contemporaries, admirers
              and other authors.
            </Paragraph>
          </Constrain>
          <Image
            image={data.turmzimmer.childImageSharp.gatsbyImageData}
            caption="This is how Hölderlin's former room in the tower looks today"
            alt="There is a small desk in the middle of the room. The sun shines through the windows on the old wooden floorboards."
          />
          <Constrain>
            <Paragraph lang="en">
              Nowadays, Hölderlin is considered one of the most important
              German-speaking poets. The tower in which he spent the last 36
              years of his life represents one of the most significant literary
              places of rememberance worldwide. Moreover, his works have been
              adapted to numerous works of literature, music and art.
            </Paragraph>
          </Constrain>
        </Stack>

        <Stack>
          <Heading as="h2" level={2}>
            The permanent exhibition
          </Heading>
          <Grid columns={[1, 2]} space={"px"}>
            <Image
              image={data.medienstation.childImageSharp.gatsbyImageData}
              alt="A listening station on Hölderlin's reception in music and literature from the permanent exhibition."
              attribution="David Franck"
            />
            <Image
              image={data.interaktion.childImageSharp.gatsbyImageData}
              alt="A poem listening station from the exhibition. Visitors can read, listen to and feel Hölderlin's poems in the exhibition. A wooden board translates the stressed syllables of his poems into vibrations."
              attribution="David Franck"
            />
          </Grid>
          <Constrain>
            <Paragraph lang="en">
              The permanent exhibition of the museum is dedicated to the periods
              of Hölderlin’s life, which he spend in Tübingen: His study years
              at the Protestant Seminary (Evangelisches Stift) (1788-1793), and
              the last 36 years of his life in the tower with the family of the
              carpenter Ernst Zimmer. Among other things, the exhibition
              provides insight into Hölderlin’s friendship with the later
              philosophers Hegel and Schelling, which were his theology course
              mates in Tübingen, as well as Hölderlin’s first publications in
              that period. The exhibition brings attention to the beauty of
              Hölderlin's so-called ›Tower-poems‹ (which he has written while
              living in the tower), and offers a sensory experience of his
              experimentations with language and rhythm.
            </Paragraph>
          </Constrain>
          <Constrain>
            <Paragraph lang="en">
              But the exhibition goes beyond the poems written in Tübingen and
              observes Hölderlin's whole literary oeuvre. Numerous literary,
              musical and artistic examples highlight Hölderlin’s ongoing
              significance – from his to our days. The alternating special
              exhibitions shed light on Hölderlin’s life and works from various
              perspectives. Artists, researchers, and readers share their
              perceptions of the poet and approaches to his works.
            </Paragraph>
          </Constrain>
        </Stack>

        <Stack>
          <Heading as="h2" level={2}>
            Audioguide and English translations{" "}
          </Heading>
          <Grid columns={[1, 2]} space={"px"}>
            <Image
              image={data.english.childImageSharp.gatsbyImageData}
              alt="The start menu of a media guide device"
            />
            <Image
              image={data.nummern.childImageSharp.gatsbyImageData}
              alt="A visitor is typing a number on the media guide device"
            />
          </Grid>
          <Constrain>
            <Paragraph lang="en">
              There is a media guide available for the tour through the
              permanent exhibition. It includes an English audio tour and
              translations of all the exhibition texts. The media guide can be
              borrowed at the museums checkout area or downloaded on one's
              phone. The audio tour permits the visitors to follow their own
              path through the exhibition, either room by room or from one
              object to the next.
            </Paragraph>
          </Constrain>
          <Constrain>
            <Paragraph lang="en">
              There is a recurring icon with a number next to each exhibition
              text. When the number is typed into the media guide, the matching
              translation for the given room- and object-texts appears. The
              English texts panels can also be read sequentially room by room.
            </Paragraph>
          </Constrain>
          <Constrain>
            <Inline space={3}>
              <Button href="https://play.google.com/store/apps/details?id=de.droidsolutions.tuebingen">
                Google Play Store
              </Button>
              <Button href="https://apps.apple.com/de/app/literaturpfad-t%C3%BCbingen/id1471621109">
                App Store
              </Button>
            </Inline>
          </Constrain>
        </Stack>

        <Constrain align="right">
          <Stack>
            <Heading as="h2" level={2}>
              Tours in English{" "}
            </Heading>
            <Paragraph lang="en">
              The museum offers tours in English and French, but they must be
              booked 14 days in advance{" "}
              <TextLink href="mailto:hoelderlinturm@tuebingen.de">
                by email
              </TextLink>
              .
            </Paragraph>
            <Paragraph lang="en">
              A guided tour lasts 60 minutes, allows a maximum of 15 people, and
              costs 60 € for the entire group.
            </Paragraph>
          </Stack>
        </Constrain>

        <Stack>
          <Heading as="h2" level={2}>
            Museum's Garden
          </Heading>
          <Image
            image={data.garten.childImageSharp.gatsbyImageData}
            caption="Museum's garden today. The first director of the clinic, Johann Heinrich Ferdinand Authenriet, had the staircase built for his patients to be able to access the Neckar River."
            alt="A large stone staircase leads to the museum garden and down to the banks of the Neckar."
            attribution="Gudrun de Maddalena"
          />
          <Constrain>
            <Paragraph lang="en">
              The garden is a part of the Hölderlin Tower. During Hölderlin’s
              lifetime, it belonged to the clinic across the street. That same
              clinic where Hölderlin received psychiatric treatment in the
              period from September 1805 to May 1806. He was diagnosed as
              mentally ill and received compulsory treatment for 231 days. The
              treatment recommended the poet to take walks, most likely in this
              very garden, adjunct to his later living quarters. After the
              dismissal from the clinic, Hölderlin was taken as a tenant in the
              tower, which was at the time home of the carpenter Ernst Zimmer.
            </Paragraph>
          </Constrain>
          <Constrain>
            <Paragraph lang="en">
              The permanent exhibition extends into the museum's garden.
              Hölderlin was very keen on talking walks – as a matter of a fact,
              he composed many of his poems while walking. The pathway in the
              garden was therefore specially designed for taking walks while
              listening to three different poems in three different speeds.
            </Paragraph>
          </Constrain>
          <Image
            image={data.laufstrecke.childImageSharp.gatsbyImageData}
            alt="A winding path in the museum garden."
          />
          <Constrain>
            <Paragraph lang="en">
              Select one of the three poems on the media guide and you will be
              able to choose the way you want the poem to be read: moderate,
              paced or fast. Then start walking along the path and try to
              determine which speed fits the poem best. When the poem is read
              out loud slowly, you have more time to reach the end of the path.
              When the poem is read out loud fast, you must hurry to reach the
              end of the path simultaneously with the end of the poem.
            </Paragraph>
          </Constrain>
        </Stack>

        <Stack>
          <Heading as="h2" level={2}>
            How to get to the Hölderlin Tower
          </Heading>
          <Grid columns={[1, 2]} alignY="center">
            <Image
              image={data.aussenansicht.childImageSharp.gatsbyImageData}
              alt="The Hölderlin Tower on the Tübingen Neckar: a yellow building with a round tower and a pointed roof."
              attribution="David Franck"
            />
            <Stack space={6}>
              <Heading as="p" level={5}>
                Museum Hölderlinturm
              </Heading>
              <Paragraph lang="en">
                Bursagasse 6 <br />
                72070 Tübingen
              </Paragraph>
            </Stack>
          </Grid>
          <Constrain>
            <Trivia title="Please note" size={3}>
              The museum is located in the old, historic and car-free district
              of Tübingen. Unfortunately, the pathway to the museum and the
              museum itself are not accessible for people with impairments,
              disabilities or wheelchairs.
            </Trivia>
          </Constrain>

          <Stack space={[12, 24]}>
            <Constrain>
              <Stack>
                <Stack space={6}>
                  <Heading as="h3" level={4}>
                    By bus
                  </Heading>
                  <Paragraph lang="en">
                    You can take bus lines 1-23. The name of the closest bus
                    stop is ›Neckarbrücke‹.
                  </Paragraph>
                </Stack>
                <Paragraph lang="en">
                  Once you exit the bus, depending on which side you are on,
                  face towards the old Neckarfront (the side with the boulevard
                  with big trees and colourful houses). That’s the direction you
                  should take. The yellow tower behind a willow tree is the
                  Hölderlin Tower. Go up the bridge until you reach the first
                  house, right on the corner.
                </Paragraph>
                <Paragraph lang="en">
                  Turn left right away, go down the round flight of stairs and
                  walk along the river straight ahead. It will guide you
                  straight to the entrance of the museum.
                </Paragraph>
              </Stack>
            </Constrain>
            <Constrain>
              <Stack>
                <Stack space={6}>
                  <Heading as="h3" level={4}>
                    By car
                  </Heading>
                  <Paragraph lang="en">
                    The closest parking lot is the ›Neckar-Parkhaus‹,
                    Woehrdstrasse 11, 72072 Tübingen. It is only a 5-10 minute
                    walk from the museum.
                  </Paragraph>
                </Stack>
                <Paragraph lang="en">
                  Walking out of the ›Neckar-Parkhaus‹, turn right and walk down
                  the street until it tees another, big street. Cross the street
                  at the traffic lights in front of Osiander Bookstore and turn
                  right to the bridge. When you almost cross the bridge, face
                  towards the old Neckarfront (the side with the boulevard with
                  big trees and colourful houses). That’s the direction you
                  should take. The yellow tower behind a willow tree is
                  Hölderlin Tower. Go up the bridge until you reach the first
                  house, right on the corner.
                </Paragraph>
                <Paragraph lang="en">
                  Turn left right away, go down the round flight of stairs and
                  walk along the river straight ahead. It will guide you
                  straight to the entrance of the museum.
                </Paragraph>
              </Stack>
            </Constrain>
          </Stack>
        </Stack>

        <Stack>
          <Heading as="h2" level={2}>
            Further reading on Friedrich Hölderlin
          </Heading>
          <Constrain>
            <BulletList size={[3, 3, 4]}>
              <Bullet>
                <TextLink href="https://en.wikipedia.org/wiki/Friedrich_H%C3%B6lderlin">
                  Wikipedia
                </TextLink>
              </Bullet>
              <Bullet>
                Friedrich Hölderlin: Poems and fragments, translated by Michael
                Hamburger with a preface, introduction and notes. 4th bilingual
                edition, London 2005.
              </Bullet>
              <Bullet>David Constantine: Hölderlin, Oxford 1990.</Bullet>
              <Bullet>
                We are like fire: Waiblinger & Hesse on Hölderlin, translated
                and with an introduction by Eric Miller, Victoria, BC 2007.
              </Bullet>
              <Bullet>
                Roy C. Shelton: The Young Hoelderlin, Frankfurt a.M. 1973.
              </Bullet>
            </BulletList>
          </Constrain>
        </Stack>

        <Stack space={[12, 24]}>
          <Heading as="h2" level={2}>
            Further sites in Tübingen linked to Hölderlin
          </Heading>
          <Columns collapse={[true, false]} space={[6, 12]}>
            <Column width={["100%", "1/3"]}>
              <Image
                image={data.stift.childImageSharp.gatsbyImageData}
                alt="A gate leads to the forecourt of the seminary. The rear part of the building still shows the historical half-timbering."
                attribution="Gudrun de Maddalena"
              />
            </Column>
            <Column>
              <Stack>
                <Stack space={3}>
                  <Heading level={3}>Das Evangelische Stift</Heading>
                  <Heading color="whisper">Protestant Seminary</Heading>
                </Stack>
                <Paragraph lang="en">
                  The Protestant Seminary is the historic dormitory of
                  Protestant theology students at the university of Tübingen. As
                  of today, students enjoy room and board here at a historic
                  setting. Hölderlin also lived there and made friends with
                  Magenau, Neuffer, Hegel and Schelling.
                </Paragraph>
              </Stack>
            </Column>
          </Columns>
          <Columns collapse={[true, false]} space={[6, 12]}>
            <Column width={["100%", "1/3"]}>
              <Image
                image={data.burse.childImageSharp.gatsbyImageData}
                alt="From the opposite bank of the Neckar you can see a pink building with two large wings behind the city wall."
                attribution="Gudrun de Maddalena"
              />
            </Column>
            <Column>
              <Stack>
                <Stack space={3}>
                  <Heading level={3}>Die Burse</Heading>
                  <Heading color="whisper">
                    Historic hostel for students
                  </Heading>
                </Stack>
                <Paragraph lang="en">
                  During Hölderlin’s lifetime the Burse building was used as a
                  clinic. Hölderlin was also treated there. Today the building
                  is part of the historic university campus.
                </Paragraph>
              </Stack>
            </Column>
          </Columns>
          <Columns collapse={[true, false]} space={[6, 12]}>
            <Column width={["100%", "1/3"]}>
              <Image
                image={data.stadtfriedhof.childImageSharp.gatsbyImageData}
                alt="Hölderlin's tombstone has the shape of an obelisk and is surrounded by green bushes and trees. There is a cross on top of the tombstone."
                attribution="Gudrun de Maddalena"
              />
            </Column>
            <Column>
              <Stack>
                <Stack space={3}>
                  <Heading level={3}>Der Stadtfriedhof</Heading>
                  <Heading color="whisper">City cemetery</Heading>
                </Stack>
                <Paragraph lang="en">
                  The cemetery where Hölderlin is buried.
                </Paragraph>
              </Stack>
            </Column>
          </Columns>
        </Stack>

        <Constrain align="right">
          <Stack>
            <Heading as="h2" level={2}>
              The Hölderlin Society
            </Heading>
            <Paragraph lang="en">
              The{" "}
              <TextLink href="https://www.hoelderlin-gesellschaft.de/">
                Hölderlin Society
              </TextLink>{" "}
              currently numbers almost 900 members worldwide, including readers,
              researchers, and numerous literary institutions. As a literary
              society, the Hölderlin Society aims at deepening the understanding
              and appreciation of Hölderlin’s work; as well as promoting
              research and portrayals of his poetry, life and time.
            </Paragraph>
          </Stack>
        </Constrain>
      </SectionSpacing>
    </Layout>
  )
}

export default English
